import React, { useEffect } from "react";
import "./HeroSection.scss";

const HeroSection = () => {
  return (
    <div className="waw-hero-container">
      <div className="hero-img"></div>
      <div className="hero-text-con">
        <div div className="waw-headline">
          <p className="p1">OUR</p>
          <p className="p2">HISTORY</p>
        </div>
        <div className="d-none hero-text wwa-text">
          Subic Bay Freeport Grain Terminal Services Inc. (Subic Grain) is a
          Philippine corporation established in 2000 as a locator in the Subic
          Bay Freeport Zone (SBFZ) to service the bulk grain importers handling
          needs in Central/Northern Luzon as well as Metro Manila. The Company's
          operating facilities were designed to handle both "HANDYMAX" and
          "PANAMAX" vessels for maximum economies of scale. Subic Grain is
          supported by its professionals with in-depth background in bulk
          handling operations with over 30 years in the port industry.
          <br />
          <br />
          Subic Bay Freeport Grain Terminal Services Inc. (Subic Grain), the
          S.T.A.R. Group's flagship company was established in 2000 as a locator
          in the Subic Bay Freeport Zone (SBFZ). The Company's operating
          facilities were designed to handle both "HANDYMAX" and "PANAMAX"
          vessels for maximum economies of scale. Subic Grain is supported by
          its professionals with in-depth background in bulk handling operations
          with over 30 years in the port industry.
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
