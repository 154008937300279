import * as React from "react";
import HeroSection from "../components/WhoWeAre/HeroSection";
import Layout from "../components/layout";
import Seo from "../components/seo";
import History from "../components/WhoWeAre/History";
import MissionVission from "../components/WhoWeAre/MissionVission";
import Milestone from "../components/WhoWeAre/Milestone";

const WhoWeAre = () => (
  <Layout>
    <Seo title="Who Are We" />
    <HeroSection />
    <History />
    <MissionVission />
    <Milestone />
  </Layout>
);

export default WhoWeAre;
